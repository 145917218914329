/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        svgeezy.init(false, 'png');

        // Activate NanoScroller
        $('.nano').nanoScroller({ sliderMaxHeight: 15, sliderMinHeight: 15 });

        // Open panels
        $('.m-mobile-toggle').click(function() {
            var self = $(this);
            var panel = self.data('target');
            $(panel).toggleClass('s-panel-open');
            $('.m-mobile-toggle').not(self).toggleClass('s-toggle-hidden');
        });

        // open form "drop-downs"
        $('.m-form-select-open').click(function() {
            var self = $(this);
            var panel = $(self.data('target'));
            // var fullHeight = $(panel).find('.ginput_container').height();
            self.toggleClass('s-is-open');
            panel.toggle();
            // if($(panel).height() > 0) {
            //     $(panel).height('0px');
            // }
            // else {
            //     $(panel).height(fullHeight);
            // }
        });

        // change file upload button when a file is added
        function checkForFiles() {   
            $('input[type="file"]').each(function() {
                var id = $(this).attr('id');
                if(document.getElementById(id).files.length > 0) {
                    $('label[for="' + id + '"]').addClass('s-file-selected');
                }
            });
            
        }
        checkForFiles();
        $('input[type="file"]').change(function() {
            checkForFiles();            
        });

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Initialize slides
        $('.m-fp-slides').slick({
            autoplay: true,
            fade: true,
            dots: true
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'blog': {
      init: function() {
        $('#m-open-archive-mobile').click(function() {
            var self = $(this);
            var archive = $(this).data('target');
            self.toggleClass('s-open');
            $(archive).toggleClass('s-archive-open');
        });
      }
    },
    'single': {
      init: function() {
        $('#m-open-archive-mobile').click(function() {
            var self = $(this);
            var archive = $(this).data('target');
            self.toggleClass('s-open');
            $(archive).toggleClass('s-archive-open');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
